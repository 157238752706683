export default class DonationProductQuestion {

    constructor() {
        this.Order = 0;
        this.id = 0;
        this.donationProductId = 0;
        this.question = '';
        this.active = true;
        this.answer = '';
        this.hasAlternatives = false;
        this.donationProductQuestionAlternatives = [];
    }
}